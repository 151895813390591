import React, { useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
  FeaturedMotorcycleDestinationsSection,
  // FeaturedMotorcycleSection,
  AboutUsSection,
  AboutUsSectionSkeleton,
  // FeaturedMotorcycleSectionSkeleton,
  FeaturedMotorcycleDestinationsSectionSkeleton,
  // LoyaltyWithMembershipPromo,
  // LoyaltyWithMembershipPromoSkeleton,
  PopularMotorcycleBrandsSection,
  PopularMotorcycleBrandsSectionSkeleton,
  PromotionCardsSection,
} from 'views/HomeView/components';
import {
  RentalsAndToursPackagesSection,
  MotorcycleAdventuresCaughtOnCamera,
  LazyLoad,
  MotorcycleAdventuresCaughtOnCameraSkelton,
  RentalsAndToursPackagesSectionSkelton,
  ParagraphWithEmbeddedObjectsSkeleton,
  ParagraphWithEmbeddedObjects,
  AppAd,
  Reward,
  RewardSkeleton,
  PromotionBanner,
} from 'views/common/components';
import { useTranslation } from 'next-i18next';
import { setGoogleAnalytics } from 'redux/features/globalData-slice';
import useAppNavigator from 'views/common/hooks/useAppNavigator';
import Head from 'next/head';
import generateMetaData from 'lib/utils/generateMetaData';
import { GA_EVENT_CATEGORY } from 'lib/utils/CONSTANTS';
import StyledBox, {
  StyledMainWidgetContainerSection,
} from 'views/HomeView/HomeView.styles';
import { hotjar } from 'react-hotjar';
import PATHS from 'lib/navigation/paths';
import getLocalizedUrl from 'lib/utils/getLocalizedUrl';
import useTranslateUrl from 'views/common/hooks/useTranslateUrl';
import {
  RentalsAndToursPackagesNewVersion,
  RentalsAndToursPackagesNewVersionSkeleton,
} from 'views/common/components/UI/RentalsAndToursPackagesNewVersion';
import { setSnackbar } from 'redux/features/ui-slice';
import dynamic from 'next/dynamic';
import { PAGES_GA_IDENTIFIERS } from 'views/common/utils/constants';

const ContinueWhereULeftOff = dynamic(() => import('views/HomeView/components').then(
  (file) => file.ContinueWhereULeftOff,
));

const { envVars } = require('next/config').default().publicRuntimeConfig;

const rentalsAndToursVersion = 'v2'; // [v1,v2]

const HomeView = (props) => {
  const { t } = useTranslation('fe_er_home_page');
  const { serverData: data, resumableReservations, initWidgetData } = props;
  const appNavigator = useAppNavigator();
  const { appState, googleAnalytics, hreflangData } = useSelector(
    (state) => state.globalData,
  );
  const dispatch = useDispatch();
  const translateUrl = useTranslateUrl();

  const [loaded, setLoaded] = useState(!!data);
  const showRewardsPromotion = () => {
    if (appState?.current_user && appState?.current_user.privileged) return false;

    const um = appState?.user_membership;
    if (um) {
      if (['active', 'within_grace_period'].includes(um.status?.sym_id)) {
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    setLoaded(true);
    hotjar.initialize(envVars.HOTJAR_SITE_ID, 6);
    dispatch(
      setGoogleAnalytics({
        ...googleAnalytics,
        newsLetterEventCategory: GA_EVENT_CATEGORY.home,
      }),
    );
    window.gtag('event', 'landing_page_view', {
      page_identifier: PAGES_GA_IDENTIFIERS.HOME,
    });
  }, []);

  const prepareMACOComponentData = (videos) => videos?.map((video) => ({
    id: video.youtube_video_id,
    videoId: video.youtube_video_id,
    title: video.title,
    subTitle: video?.subtitle,
    bgImg: video.image_url,
    description: video.description,
    path: video.link.url,
    pathLable: video.link.text,
  }));

  useEffect(() => {
    if (
      localStorage.getItem('resetPassword')
      || localStorage.getItem('resetPasswordError')
    ) {
      const msg = localStorage.getItem('resetPassword-msg');

      dispatch(
        setSnackbar({
          open: true,
          severity: localStorage.getItem('resetPassword') ? 'success' : 'error',
          message: msg,
          duration: localStorage.getItem('resetPassword') ? null : 100000,
        }),
      );
      localStorage.removeItem('resetPassword');
      localStorage.removeItem('resetPasswordError');
      localStorage.removeItem('resetPassword-msg');
    }
  }, []);

  return (
    <StyledBox className="HVRoot">
      {data?.seo_values && <Head>{generateMetaData(data.seo_values)}</Head>}

      {data.eligible_ads?.length > 0 && (
        <PromotionBanner
          bannersList={data.eligible_ads}
          pageName={GA_EVENT_CATEGORY.home}
        />
      )}

      <StyledMainWidgetContainerSection
        WidgetBackgroundImage={data?.cover_image}
        smallHeroImg={data?.is_cover_image_shrunk}
        rentalWidgetInitData={initWidgetData}
      />

      <Container maxWidth="xl">
        {resumableReservations.length > 0 && (
          <Box my={6}>
            <ContinueWhereULeftOff
              resumableReservations={resumableReservations}
            />
          </Box>
        )}
        {data?.promotions.length > 0 && (
          <Box my={6} className="HDealsSectionContainer">
            <PromotionCardsSection promotions={data.promotions} />
          </Box>
        )}

        <Box my={6}>
          <LazyLoad
            skeleton={<PopularMotorcycleBrandsSectionSkeleton />}
            dataLoaded={loaded}>
            <PopularMotorcycleBrandsSection
              promotedVehicleMakes={data?.promoted_vehicle_makes}
            />
          </LazyLoad>
        </Box>

        <Box my={6}>
          <LazyLoad
            skeleton={<FeaturedMotorcycleDestinationsSectionSkeleton />}
            dataLoaded={loaded}>
            <FeaturedMotorcycleDestinationsSection
              destinationCards={data?.touts}
            />
          </LazyLoad>
        </Box>
        {/* <Box  mb={6} >
          <FeaturedMotorcycleSection
            bikeCards={bikeCards}
            buttons={[
              {
                text: t('fe_er_home_page:explore_all'),
                path: '/a',
              },
            ]}
          />
        </Box> */}
      </Container>

      <Box>
        <LazyLoad
          skeleton={<MotorcycleAdventuresCaughtOnCameraSkelton />}
          dataLoaded={loaded}>
          <MotorcycleAdventuresCaughtOnCamera
            AdventuresOnCameraItems={prepareMACOComponentData(data?.videos)}
          />
        </LazyLoad>
      </Box>

      <Container maxWidth="xl">
        {rentalsAndToursVersion === 'v1' && (
          <LazyLoad
            skeleton={<RentalsAndToursPackagesSectionSkelton />}
            dataLoaded={loaded}>
            <Box my={6}>
              <RentalsAndToursPackagesSection />
            </Box>
          </LazyLoad>
        )}
        {rentalsAndToursVersion === 'v2' && (
          <LazyLoad
            skeleton={<RentalsAndToursPackagesNewVersionSkeleton />}
            dataLoaded={loaded}>
            <Box my={6}>
              <RentalsAndToursPackagesNewVersion />
            </Box>
          </LazyLoad>
        )}
        {showRewardsPromotion() && (
          <Box my={6}>
            <LazyLoad skeleton={<RewardSkeleton />} dataLoaded={loaded}>
              <Reward
                loggedInUser={!!appState?.current_user}
                offValue={appState?.reward?.deal?.discount_percent}
              />
            </LazyLoad>
          </Box>
        )}
        <LazyLoad
          skeleton={<ParagraphWithEmbeddedObjectsSkeleton />}
          dataLoaded={loaded}>
          <Box mb={6}>
            <ParagraphWithEmbeddedObjects
              title={t('fe_er_home_page:eagleRider_motorcycle_rentals_tours')}
              desc={t('fe_er_home_page:paragraph_with_embedded_objects_desc', {
                one_way_path: hreflangData.canonical,
                popular_trips_url: getLocalizedUrl(
                  translateUrl(PATHS.motorcycleTrips.index().url),
                  appNavigator.locale,
                ),
                guided_motorcycle_tours_url: getLocalizedUrl(
                  translateUrl(PATHS.guidedTourIndex().url),
                  appNavigator.locale,
                ),
              })}
              titleComponet="h1"
              collapsedSize={99}
            />
          </Box>
          <Box my={6} className="showDESKTOP">
            <AppAd
              title={t('fe_er_home_page:one_app')}
              desc={t('fe_er_home_page:download_Eaglerider')}
            />
          </Box>
        </LazyLoad>
      </Container>

      <LazyLoad skeleton={<AboutUsSectionSkeleton />} dataLoaded={loaded}>
        <Box mb={6}>
          <AboutUsSection />
        </Box>
      </LazyLoad>
      {/* {loaded && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(data?.structured_data),
          }}
        />
      )} */}
    </StyledBox>
  );
};
export { HomeView };
